import { useTranslation } from 'react-i18next';
import { CrudMetadata, EntityItem, BulkAction } from '../../../../types';
import { useNavigate } from 'react-router-dom';
import Tag from '@components/Tag/Tag';
import TableTd from './TableTd';
import TextEllipsisWithPopup from '@components/TextEllipsisWithPopup/TextEllipsisWithPopup';
import Checkbox from '@components/Checkbox/Checkbox';
import MultiActionsButton from '@components/MultiActionsButton/MultiActionsButton';
import styles from './ListSearchResultLine.module.scss';


export default function ListSearchResultLine({
  metadata,
  result,
  selected,
  onSelect,
  isLoading,
  hasTagsColumn,
  onClickAction,
  onClickBulkAction,
  isSticky,
  hasActions,
  hasNavs,
  className,
}: {
  metadata: CrudMetadata;
  result: EntityItem;
  selected?: boolean;
  onSelect?: (selected: boolean) => void;
  isLoading?: boolean;
  hasTagsColumn: boolean;
  onClickAction?: (actionKey: string, entity: EntityItem) => void;
  onClickBulkAction?: (bulkAction: BulkAction, uniqueActionId: string) => void;
  isSticky?: boolean;
  hasActions?: boolean;
  hasNavs?: boolean;
  className?: string;
}) {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const idField = result?.[metadata?.idField] 
  const displayDetails = result?.actions?.get?.href?.includes("assignments")


  return (
    <tr
      className={className}
    >
      {!!onSelect && (
        <TableTd isLoading={isLoading}>
          <Checkbox
            checked={selected}
            onChange={onSelect}
          />
        </TableTd>
      )}
      {hasTagsColumn && (
        <TableTd isLoading={isLoading}>
          {isSticky && (
            <div className={"flex flex-row"}>
              <Tag
                label={t('default')}
                color={"var(--tag-default-background)"}
                textColor={"var(--tag-default-text)"}
              />
            </div>
          )}
        </TableTd>
      )}
      {metadata.listView.columns.map(col => (
        <TableTd isLoading={isLoading} key={col.id}>
          {(() => {
            if (!result[col.id]) {
              return null;
            }
            const fieldMetadata = metadata.fields[col.id]
            switch (fieldMetadata?.type) {
              case 'datetime':
                return (new Date(result[col.id])).toLocaleString(navigator.language, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit"
                });
              case 'boolean':
                return result[col.id] ? t('yes') : t('no');
              case 'number':
                return result[col.id];
              case 'string':
                return (
                  <TextEllipsisWithPopup
                    containerProps={{
                      style: {
                        maxWidth: '150px',
                      }
                    }}
                    popupMaxWidth='500px'
                  >
                    {result[col.id]}
                  </TextEllipsisWithPopup>
                );
              case 'select':
                return fieldMetadata.options?.find(option => option.id === result[col.id])?.label;
              case 'select-img':
                return (
                  <img
                    src={result[col.id]}
                    alt={fieldMetadata.options?.find(option => option.id === result[col.id])?.label}
                    className={styles.img}
                  />
                );

              case 'ranked-list':
              case 'multi-select':
                return (
                  <TextEllipsisWithPopup
                    containerProps={{
                      style: {
                        maxWidth: '150px',
                      }
                    }}
                    popupMaxWidth='300px'
                  >
                    {fieldMetadata.options?.filter(option => result[col.id].map((o: any) => o.id).includes(option.id)).map(option => option.label).join(', ') || ""}
                  </TextEllipsisWithPopup>
                );
              default:
                return null;
            }
          })()}
        </TableTd>
      ))}

      {(hasActions || hasNavs) && (
        <TableTd isLoading={isLoading} containerClassName="flex flex-row justify-center gap-4">

          <MultiActionsButton
            actions={[
              ...((result.navs || []).map(navAction => ({
                label: navAction.label,
                onClick: () => {
                  if (navAction.href.startsWith('http')) {
                    window.open(navAction.href, '_blank');
                  } else {
                    const a = document.createElement('a');
                    a.href = navAction.href;
                    navigate(a.pathname + a.search);
                  }
                }
              }))),

              ...Object.keys(result.actions || {}).filter(key => key === "update" || key === "delete").map(actionKey => ({
                label: t(`${actionKey}`),
                onClick: () => {
                  onClickAction && onClickAction(actionKey, result);
                }
              })),
              ...(displayDetails
                ? Object.keys(result.actions || {})
                    .filter((key) => key === "get")
                    .map((actionKey) => ({
                      label: t("details"),
                      onClick: () => {
                        console.log("result REEAL", result);
                        onClickAction && onClickAction(actionKey, result);
                      },
                    }))
                : []),
              ...(metadata?.bulkActions || []).map(bulkAction => ({
                label: bulkAction.label,
                onClick: () => {
                  onClickBulkAction && onClickBulkAction(bulkAction, idField);
                }
              })),
            ]}
            buttonProps={{
              type: 'secondary',
              containerProps:{
                className:styles.buttonActions,
                style: {
                  height: '30px',
                }
              }
            }}
          />
        </TableTd>
      )}

    </tr>
  )
}
