import React from "react";
import styles from "./TravelPath.module.scss";
import TextEllipsisWithPopup from "@components/TextEllipsisWithPopup/TextEllipsisWithPopup";

export default function TravelPath({
  from,
  to,
  travelInfo,
  noHeight100,
  containerProps: { className, ...containerProps } = {},
}: {
  from: {
    labelLeft: string;
    labelRight: string;
  };
  to: {
    labelLeft: string;
    labelRight: string;
  };
  noHeight100?: boolean;
  travelInfo: {
    left: React.ReactNode;
    right: React.ReactNode;
  };
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  return (
    <div
      className={[
        styles.container,
        noHeight100 ? styles.noHeight100 : "",
        className,
      ].join(" ")}
      {...containerProps}
    >
      <div className={[styles.step, styles.stepFrom].join(" ")}>
      <div className={`${styles.leftLabelContainer} leftLabelContainer`}>
          <div className={styles.leftLabel}>{from.labelLeft}</div>
        </div>

        <div className={styles.badgeContainer}>
          <div className={styles.badge} />
          <div className={styles.separatorInsideBadgeContainer}>
            <div className={styles.separatorInsideBadge} />
          </div>
        </div>

        <div className={styles.rightLabelContainer}>
          <TextEllipsisWithPopup
            containerProps={{
              className: styles.rightLabel,
            }}
          >
            {from.labelRight}
          </TextEllipsisWithPopup>
        </div>
      </div>

      <div className={styles.interStepContainer}>
        <div className={`${styles.leftLabelContainer} leftLabelContainer`}>
          <div className={styles.leftLabel}>{travelInfo.left}</div>
        </div>
        <div className={styles.separatorContainer}>
          <div className={styles.separator} />
        </div>
        <div className={styles.rightLabelContainer}>
          <div className={styles.transportLabel}>{travelInfo.right}</div>
        </div>
      </div>

      <div className={[styles.step, styles.stepTo].join(" ")}>
      <div className={`${styles.leftLabelContainer} leftLabelContainer`}>
          <div className={styles.leftLabel}>{to.labelLeft}</div>
        </div>

        <div className={styles.badgeContainer}>
          <div className={styles.badge} />
          <div className={styles.separatorInsideBadgeContainer}>
            <div className={styles.separatorInsideBadge} />
          </div>
        </div>

        <div className={styles.rightLabelContainer}>
          <TextEllipsisWithPopup
            containerProps={{
              className: styles.rightLabel,
            }}
          >
            {to.labelRight}
          </TextEllipsisWithPopup>
        </div>
      </div>
    </div>
  );
}
