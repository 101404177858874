import { useTranslation } from 'react-i18next';
import styles from './ContractForm.module.scss';
import stylesForm from '../Form.module.scss';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Select from '@components/Select/Select';
import { useEffect, useState } from 'react';
import { authenticatedApi } from 'src/Api/api';
import { useConfirmAlert } from 'src/Stores/ConfirmAlertStore';

type FormData = {
  contract: number | null;
  eventOutcome: number | null;
  subcover: number | null;
};

export default function ContractForm({
  editable,
  onChangeEditState,
  layout = {
    case: true,
    mission: true,
    beneficiaryName: true,
    beneficiaryPhone: true,
    licensePlate: true,
    changeCaseAction: true,
    changeMissionAction: true,
  },
  testid,
  containerProps: { className, ...containerProps } = {},
}: {
  editable?: boolean;
  onChangeEditState?: ({
    isEditing,
    isLoading,
  }: {
    isEditing: boolean;
    isLoading: boolean;
  }) => any;
  layout?: {
    case?: boolean;
    mission?: boolean;
    beneficiaryName?: boolean;
    beneficiaryPhone?: boolean;
    licensePlate?: boolean;
    changeCaseAction?: boolean;
    changeMissionAction?: boolean;
  };
  testid?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { confirm } = useConfirmAlert();

  const { mission, fileCaseContractForm, lockContractID, setMission, setMissionContext } = useCurrentMission();
  const navigate = useNavigate();
  const contracts = fileCaseContractForm;

  const caseID = mission?.caseID;

  const defaultValues = {
    contract: mission?.contractId || null,
    eventOutcome: mission?.eventOutcomeId || null,
    subcover: mission?.subcoverId || null,
  }

  const form = useForm<FormData>({
    defaultValues,
  });
  const { control, formState: { isDirty }, watch, reset } = form;

  const contractId = watch("contract");
  const eventOutcomeId = watch("eventOutcome");

  const eventOutcomes = (contracts || []).find((contract) => contract.id === contractId)?.eventOutcomes || [];
  const subcovers = (contracts || []).find((contract) => contract.id === contractId)?.eventOutcomes.find((eventOutcome) => eventOutcome.id === eventOutcomeId)?.subcovers || [];

  useEffect(() => {
    if (onChangeEditState) {
      onChangeEditState({
        isEditing: isDirty,
        isLoading,
      });
    }
  }, [isDirty, isLoading, onChangeEditState])

  const submitAction = form.handleSubmit(async (data) => {
    if (!form.formState.isDirty) {
      return false;
    }

    if (!mission?.updateAction) {
      return false;
    }

    setIsLoading(true);

    try {
      const response = await authenticatedApi.request({
        url: mission.updateAction.href,
        method: mission.updateAction.method,
        data: {
          contractID: data.contract,
          eventOutcomeID: data.eventOutcome,
          subcoverID: data.subcover,
        }
      });
      if (!response.data.mission || !response.data.context) {
        throw new Error("missing mission in response");
      }
      setMission(response.data.mission);
      setMissionContext(response.data.context);
      reset(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }

  });

  if (!mission) {
    return null;
  }

  return (
    <div className={[styles.container, stylesForm.form, className].join(" ")} {...containerProps} data-testid={testid}>

      {layout.case && (
        layout.changeCaseAction ? (
          <div className={stylesForm.formRow}>
            <div className={stylesForm.formCol}>{t('case_number')} <span className={stylesForm.formValueLeft}>{caseID}</span></div>
            <div className={stylesForm.formCol}><a onClick={(e) => {

              e.preventDefault();
              confirm({
                title: t('change_case'),
                message: t('change_case_confirm'),
                confirmLabel: t('optionOui'),
                onConfirm: () => {
                  navigate("/");
                },
                cancelLabel: t('optionNon'),
                onCancel: () => void 0,
              })
              return false;
            }} className={`${stylesForm.formLink} ${styles.formLink}`}>{t('change_case')}</a></div>
          </div>
        ) : (
          <div className={stylesForm.formRow}>
            <div className={stylesForm.formCol}>{t('case_number')}</div>
            <div className={stylesForm.formCol}>{caseID}</div>
          </div>
        )
      )}

      {layout.mission && (
        layout.changeMissionAction ? (
          <div className={stylesForm.formRow}>
            <div className={stylesForm.formCol}>{t('mission_number')} <span className={stylesForm.formValueLeft}>{mission.id}</span></div>
            <div className={stylesForm.formCol}><a onClick={(e) => {
              e.preventDefault();

              confirm({
                title: t('change_mission_link'),
                message: t('change_mission_confirm'),
                confirmLabel: t('optionOui'),
                onConfirm: () => {
                  navigate("./../..");
                },
                cancelLabel: t('optionNon'),
                onCancel: () => void 0,
              })
              return false;
            }} data-testid='button-change-mission' className={`${stylesForm.formLink} ${styles.formLink}`}>{t('change_mission_link')}</a></div>
          </div>

        ) : (
          <div className={stylesForm.formRow}>
            <div className={stylesForm.formCol}>{t('mission_number')}</div>
            <div className={stylesForm.formCol}>{mission.id}</div>
          </div>
        )

      )}

      {layout.beneficiaryName && (
      <div className={stylesForm.formRow}>
        <div className={stylesForm.formCol}>{t('beneficiary_name')}</div>
        <div className={stylesForm.formCol}>{`${mission.clientFirstname} ${mission.clientLastname}`}</div>
      </div>
      )}

      {layout.beneficiaryPhone && (
      <div className={stylesForm.formRow}>
        <div className={stylesForm.formCol}>{t('beneficiary_mobile_number')}</div>
        <div className={stylesForm.formCol}>{mission.clientPhone ? mission.clientPhone.split(" ").slice(1).join("") : ""}</div>
      </div>
      )}

      {layout.licensePlate && (
      <div className={stylesForm.formRow}>
        <div className={stylesForm.formCol}>{t('license_plate')}</div>
        <div className={stylesForm.formCol}>{mission.licensePlate}</div>
      </div>
      )}

      {editable && (
        <table className='w-full'>
          <tbody className='w-full'>
            <tr>
              <td className={`${stylesForm.formCol} ${styles.tableRowLabel}`}>{t('contract_label')}</td>
              <td>
                <Controller
                  name="contract"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('contract_required_message')
                    }
                  }}
                  render={({ field }) => (
                    <Select
                      disabled={isLoading || lockContractID === true}
                      testid="select-contract"
                      placeholder={t('contract_placeholder')}
                      optionsV2={(contracts || []).map((contract) => ({
                        label: contract.label,
                        value: contract.id,
                      }))}
                      selectedIndex={(contracts || []).findIndex((contract) => contract.id === field.value)}
                      onChangeIndex={(selectedIndex) => {
                        if (selectedIndex === null) {
                          field.onChange(null);
                        } else if (contracts && contracts.length > 0) {
                          field.onChange(contracts[selectedIndex].id);
                        }
                        form.setValue("eventOutcome", null);
                        form.setValue("subcover", null);
                      }}
                      containerProps={{
                        className: "w-full",
                      }}
                    />

                  )}
                />
              </td>
            </tr>

            <tr>
              <td className={`${stylesForm.formCol} ${styles.tableRowLabel}`}>{t('event_label')}</td>
              <td>
                <Controller
                  name="eventOutcome"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('event_required_message')
                    }
                  }}
                  render={({ field }) => (
                    <Select
                      disabled={isLoading}
                      testid="select-event-outcome"
                      placeholder={t('event_placeholder')}
                      optionsV2={eventOutcomes.map((eventOutcome) => ({
                        label: eventOutcome.label,
                        value: eventOutcome.id,
                      }))}
                      selectedIndex={eventOutcomes.findIndex((eventOutcome) => {
                        return eventOutcome.id === field.value
                      })}
                      onChangeIndex={(selectedIndex) => {
                        if (selectedIndex === null) {
                          field.onChange(null);
                        } else {
                          field.onChange(eventOutcomes[selectedIndex].id);
                        }
                        form.setValue("subcover", null);
                      }}
                      containerProps={{
                        className: "w-full",
                      }}
                    />

                  )}
                />
              </td>
            </tr>

            <tr>
              <td className={`${stylesForm.formCol} ${styles.tableRowLabel}`}>{t('subcover_label')}</td>
              <td width={250}>
                <Controller
                  name="subcover"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('subcover_required_message')
                    }
                  }}
                  render={({ field }) => (
                    <Select
                      disabled={isLoading}
                      testid="select-subcover"
                      placeholder={t('subcover_placeholder')}
                      optionsV2={subcovers.map((subcover) => ({
                        label: subcover.label,
                        value: subcover.id,
                      }))}
                      selectedIndex={subcovers.findIndex((subcover) => subcover.id === field.value)}
                      onChangeIndex={(selectedIndex) => {
                        if (selectedIndex === null) {
                          field.onChange(null);
                          return;
                        }
                        field.onChange(subcovers[selectedIndex].id);
                        setTimeout(() => {
                          submitAction();
                        }, 0)
                      }}
                      containerProps={{
                        className: "w-full",
                      }}
                    />

                  )}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {!editable && (
        <>
          <div className={stylesForm.formRow}>
            <div className={stylesForm.formCol}>{t('editable_contract')}</div>
            <div className={`${stylesForm.formCol} ${styles.formColVal}`}>{mission.contract}</div>
          </div>

          <div className={stylesForm.formRow}>
            <div className={stylesForm.formCol}>{t('editable_event')}</div>
            <div className={`${stylesForm.formCol} ${styles.formColVal}`}>{mission.eventOutcome}</div>
          </div>

          <div className={stylesForm.formRow}>
            <div className={stylesForm.formCol}>{t('editable_subcover')}</div>
            <div className={`${stylesForm.formCol} ${styles.formColVal}`}>{mission.subcover}</div>
          </div>
        </>
      )}
    </div>
  )
}