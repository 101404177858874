


export function getAppEnv(): 'local' | 'int' | 'staging' | 'uat' | 'preprod' | 'production' {
  switch (window.location.hostname) {
    case 'local.int.awp.thetreep.com':
    case '127.0.0.1':
      return 'local';
    case 'int.awp.thetreep.com':
      return 'int';
    case 'staging.awp.thetreep.com':
      return 'staging';
    case 'uat.awp.thetreep.com':
      return 'uat';
    case 'preprod.agent.maas.mondial-assistance.com':
      return 'preprod';
    default:
      return 'production';
  }
}

export function isAppEnvDev() {
  return getAppEnv() === 'int' || getAppEnv() === 'local';
}

export function isAppEnvDevOrStaging() {
  return getAppEnv() === 'staging' || getAppEnv() === 'int' || getAppEnv() === 'local';
}

export function isAppEnvProductionLike() {
  const appEnv = getAppEnv();
  return appEnv === 'production' || appEnv === 'preprod';
}

export function isAppEnvProduction() {
  const appEnv = getAppEnv();
  return appEnv === 'production';
}
