import styles from "./DetailsAssignementsModal.module.scss";
import stylesForm from "@components/Form/Form.module.scss";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiAction } from "src/models/ApiAction";
import { publicApi } from "src/Api/api";
import { format } from "date-fns";
import TextEllipsisWithPopup from "@components/TextEllipsisWithPopup/TextEllipsisWithPopup";

interface Detail {
  label: string;
  value: string | number;
  isLink?: boolean;
}

interface DetailMapping {
  [category: string]: Detail[];
}

export default function DetailsAssignementsModal({
  action,
  onRequestClose,
  containerProps: { className, ...containerProps } = {},
}: {
  action: ApiAction;
  onRequestClose?: () => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const fetchDetails = async () => {
    try {
      setLoading(true);
      const response = await publicApi.request({
        method: action.method.toUpperCase(),
        url: action.href,
      });
      const data = response?.data?.data;
      setData(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const noData = "_";

  const getValue = (value: any, currency?: string) => {
    console.log("currency", currency);
    if (value !== null && value !== "" && value !== undefined) {
      return currency ? `${value} ${currency}` : value;
    }
    return noData;
  };

  const detailMapping: DetailMapping = {
    general_info: [
      {
        label: t("assignment"),
        value: getValue(data?.assignment),
      },
      { label: t("status"), value: getValue(data?.status) },
      { label: t("case_file"), value: getValue(data?.case) },
      { label: t("mission"), value: getValue(data?.mission) },
    ],
    provider_info: [
      {
        label: t("provider_ref"),
        value: getValue(data?.providerReference),
      },
      {
        label: t("comet_link"),
        value: getValue(data?.providerBackOfficeURL),
        isLink: true,
      },
    ],
    travel_details: [
      {
        label: t("departure_date"),
        value:
          (data?.startTime &&
            format(new Date(data?.startTime), "dd/MM/yyyy  HH:mm")) ||
          0,
      },
      {
        label: t("arrival_date"),
        value:
          (data?.startTime &&
            format(new Date(data?.endTime), "dd/MM/yyyy  HH:mm")) ||
          0,
      },
      {
        label: t("departure_location"),
        value: getValue(data?.origin?.name),
      },
      {
        label: t("arrival_location"),
        value: getValue(data?.destination?.name),
      },
      {
        label: t("total_distance_meters"),
        value: getValue(data?.distanceInMeters),
      },
      { label: t("co2_emissions_amount"), value: getValue(data?.gCo2eq) },
      {
        label: t("eticket_link"),
        value: getValue(data?.evouchers),
        isLink: true,
      },
    ],
    vehicle_info: [
      {
        label: t("vehicle_type"),
        value: getValue(data?.vrentalABSCategory),
      },
      { label: t("booking_class"), value: getValue(data?.vrentalACRISS) },
    ],
    billing_info: [
      {
        label: t("total_cost"),
        value: getValue(
          data?.totalAmount?.formatted,
          data?.totalAmount?.currency?.symbol
        ),
      },
      {
        label: t("tax_amount"),
        value: getValue(
          data?.taxAmount?.formatted,
          data?.taxAmount?.currency?.symbol
        ),
      },
      {
        label: t("amount_covered"),
        value: getValue(
          data?.coveredAmount?.formatted,
          data?.coveredAmount?.currency?.symbol
        ),
      },
      {
        label: t("remaining_amount"),
        value: getValue(
          data?.remainingAmount?.formatted,
          data?.coveredAmount?.currency?.symbol
        ),
      },
      {
        label: t("train_conditions_rates"),
        value: getValue(data?.trainDetails),
      },
      {
        label: t("goodwill_amount"),
        value: getValue(
          data?.goodwillAmount?.formatted,
          data?.coveredAmount?.currency?.symbol
        ),
      },
      { label: t("goodwill_type"), value: getValue(data?.goodwillType) },
    ],
    guarantees_info: [
      {
        label: t("covered_vrental_days"),
        value: getValue(data?.nbVrentalDaysCovered),
      },
      {
        label: t("remaining_vrental_days"),
        value: getValue(data?.nbVrentalDaysOutOfPocket),
      },
      {
        label: t("limiting_rules"),
        value: getValue(data?.limitingGuaranteesRule),
      },
    ],
  };

  return (
    <Modal
      onRequestClose={loading ? () => void 0 : onRequestClose}
      title={t("details")}
    >
      <div
        className={[stylesForm.form, styles.modalContainer, className].join(
          " "
        )}
        {...containerProps}
      >
        {data && (
          <div className={styles.details}>
            {Object.entries(detailMapping).map(([categoryKey, details]) => (
              <div key={categoryKey} className={styles.category}>
                <div className={styles.categoryTitle}>{t(categoryKey)}</div>
                {details.map((detail, index) => (
                  <div key={index} className={styles.detail}>
                    <div className={styles.detailLabel}>
                      {detail.label}
                      {t("colon")}
                    </div>
                    {detail.isLink ? (
                      <a
                        href={String(detail.value)}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.detailValue}
                      >
                        {detail.value}
                      </a>
                    ) : (
                      <TextEllipsisWithPopup
                        containerProps={{
                          className: [styles.detailValue].join(" "),
                        }}
                      >
                        {detail.value}
                      </TextEllipsisWithPopup>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}

        <Button
          label={t("close")}
          type="secondary"
          containerProps={{
            className: stylesForm.buttonCancel,
            disabled: loading,
          }}
          onClick={() => onRequestClose && onRequestClose()}
        />
      </div>
    </Modal>
  );
}
