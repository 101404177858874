import { IconShare } from "@assets/icons/icons";
import Button from "@components/Button/Button";
import { useState } from "react";
import ModalSendLink from "./SendLinkModal";
import { useTranslation } from 'react-i18next';
import {MissionContextType} from "../../../Stores/CurrentMissionTypes/MissionContextType.ts";

export default function SendLinkButton({
  type,
  label,
  getAdditionnalData,
  buttonType = 'primary',
  containerProps,
  step,
}: {
  type: 'selfcare' | 'refund';
  label?: string;
  getAdditionnalData?: () => Promise<any>;
  buttonType?: 'primary' | 'secondary';
  containerProps?: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;
  step?: keyof MissionContextType['steps'];
}) {

  const { t } = useTranslation();
  const [displayModalSendLink, setDisplayModalSendLink] = useState<{ open: false } | {
    type: 'selfcare' | 'refund';
    data?: any;
    open: true;
  }>({
    open: false
  });

  console.log("displayModalSendLink", displayModalSendLink);

  return (
    <>
      {displayModalSendLink && displayModalSendLink.open === true && (
        <ModalSendLink
          type={displayModalSendLink.type}
          additionnalData={displayModalSendLink.data}
          onRequestClose={() => setDisplayModalSendLink({
            open: false
          })}
          step={step}
        />
      )}
      <Button
        type={buttonType}
        onClick={async () => {
          const data = getAdditionnalData ? (await getAdditionnalData()) : undefined;
          setDisplayModalSendLink({
            open: true,
            data,
            type
          });
        }}
        containerProps={containerProps}
      >
        {type === 'refund' ? (
          <>
            <IconShare /> {label || t('refund_label_default')}
          </>
        ) : null}
        {type === 'selfcare' ? (
          <>
            <IconShare /> {label || t('search_label_default')}
          </>
        ) : null}
      </Button>

    </>
  )
}
