import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { useCallback, useEffect } from 'react';
import { publicApi } from 'src/Api/api';
import { ContractFormType } from 'src/models/ContractFormType';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import { MissionType } from 'src/Stores/CurrentMissionTypes/MissionType';

function findAllIdsFromNames({
  contractName,
  eventOutcomeName,
  subcoverName,
  contracts
}: {
  contractName: string;
  eventOutcomeName: string;
  subcoverName: string;
  contracts: Array<ContractFormType>;
}) {

  const contractId = contracts.find((contract) => contract.label === contractName)?.id;
  const eventOutcomeId = contracts.find((contract) => contract.label === contractName)?.eventOutcomes.find((eventOutcome) => eventOutcome.label === eventOutcomeName)?.id;
  const subcoverId = contracts.find((contract) => contract.label === contractName)?.eventOutcomes.find((eventOutcome) => eventOutcome.label === eventOutcomeName)?.subcovers.find((subcover) => subcover.label === subcoverName)?.id;

  return {
    contractId,
    eventOutcomeId,
    subcoverId,
  }
}

export default function CurrentMissionContainer() {

  const { caseId, missionId } = useParams<{caseId: string; missionId: string;}>();
  const { mission, setAll } = useCurrentMission();

  const navigate = useNavigate();

  const fetchFileAndMission = useCallback(async (): Promise<{ mission: any; } | null> => {

    const [
      missionResponse,
      missionFormResponse,
    ] = await Promise.all([
      publicApi.get(`/cases/${caseId}/missions/${missionId}`),
      publicApi.get(`/cases/${caseId}/missionForm`),
    ]);

    if (missionResponse.data?.mission) {
      const mission = missionResponse.data.mission as MissionType;
      const fileCaseContractForm = missionFormResponse.data.missionForm.contracts;
      const lockContractID = missionFormResponse.data.missionForm.lockContractID;

      const {
        contractId,
        eventOutcomeId,
        subcoverId,
      } = findAllIdsFromNames({
        contractName: mission.contract,
        eventOutcomeName: mission.eventOutcome,
        subcoverName: mission.subcover,
        contracts: fileCaseContractForm,
      });

      mission.contractId = contractId;
      mission.eventOutcomeId = eventOutcomeId;
      mission.subcoverId = subcoverId;

      setAll({
        file: null,
        mission,
        missionContext: missionResponse.data.context,
        fileCaseContractForm,
        lockContractID,
      })

      return {
        mission: missionResponse.data.mission,
      }
    }
    return null;
  }, [caseId, missionId, setAll]);

  useEffect(() => {
    (async () => {
      const { mission } = (await fetchFileAndMission()) || {};
      if (!mission) {
        navigate("/create-case", {
          state: {
            caseId,
          }
        });
      }
    })();
  }, [caseId, navigate, fetchFileAndMission]);

  useEffect(() => {
    return () => {
      setAll({
        file: null,
        mission: null,
        missionContext: null,
        fileCaseContractForm: null,
        lockContractID: null,
      })
    }
  }, [setAll]);

  if (!mission) {
    return <FullScreenLoader />;
  }

  return (
    <Outlet />
  )
}