import { useCallback, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './OriginDestInput.module.scss';
import { DoubleArrow } from '@assets/icons/icons';
import AutocompleteInput from '@components/AutocompleteInput/AutocompleteInput';
import { authenticatedApi } from 'src/Api/api';
import { SuggestionPlaceType } from 'src/models/Place';
import { isSelfCare } from 'src/Stores/AppContext';
import { useTranslation } from 'react-i18next';


const firstSuggestion = {
  id: "currentLocation",
  name: "Ma position actuelle",
  isCurrentLocation: true,
  sessionToken: "origin_session_token"
}

export default function OriginDestInput({
  onlyOrigin,
  disabled,
  defaultOrigin,
  defaultDestination,
  label,
  errorMsg,
  onChangeOrigin,
  onChangeDestination,
  mode = 'inline',
  containerProps: { className, ...containerProps } = {},
  testid,
}: {
  onlyOrigin?: boolean;
  disabled?: boolean;
  defaultOrigin?: SuggestionPlaceType | null;
  defaultDestination?: SuggestionPlaceType | null;
  label?: string;
  errorMsg?: string;
  onChangeOrigin? : (origin?: SuggestionPlaceType | null) => any;
  onChangeDestination?: (destination?: SuggestionPlaceType | null) => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  readOnly?: boolean;
  mode?: 'inline' | 'modal';
  testid?: string;
}) {

  const { t } = useTranslation();
  const [reversed, setReversed] = useState(false);
  const [origin, setOrigin] = useState<SuggestionPlaceType | undefined | null>(defaultOrigin);
  const [destination, setDestination] = useState<SuggestionPlaceType | undefined | null>(defaultDestination);

  const uuidOrigin = useRef(defaultOrigin?.sessionToken || uuidv4());
  const uuidDestination = useRef(defaultDestination?.sessionToken || uuidv4());

  const getSuggestionsOrigin = useCallback(async (value: string) => {
    const res = await authenticatedApi.get("/places/autocomplete", {
      params: {
        query: value,
        sessionToken: uuidOrigin.current
      }
    });
    return res.data.places;
  }, []);

  const getSuggestionsDestination = useCallback(async (value: string): Promise<SuggestionPlaceType[]> => {
    const res = await authenticatedApi.get("/places/autocomplete", {
      params: {
        query: value,
        sessionToken: uuidDestination.current
      }
    });
    return res.data.places as SuggestionPlaceType[];
  }, []);

  const onClickReverse = () => {
    if (disabled) {
      return;
    }
    setReversed(!reversed);

    triggerChange(origin, destination, !reversed);
  }

  const setOriginDest = (suggestion: SuggestionPlaceType | null, isOrigin: boolean) => {
    if (isOrigin) {
      setOrigin(suggestion);
    } else {
      setDestination(suggestion);
    }
  }

  const triggerChange = useCallback((
    origin: SuggestionPlaceType | undefined | null,
    destination: SuggestionPlaceType | undefined | null,
    reversed: boolean
  ) => {
    if (reversed) {
      onChangeOrigin && onChangeOrigin(destination);
      onChangeDestination && onChangeDestination(origin);
    } else {
      onChangeOrigin && onChangeOrigin(origin);
      onChangeDestination && onChangeDestination(destination);
    }
  }, [onChangeOrigin, onChangeDestination]);

  return (
    <div data-testid={testid} className={[styles.container, disabled ? styles.disabled : "", styles[`container_default`], className].join(" ")} {...containerProps}>
      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div className={styles.errorMsg} data-testid={`error-message-${testid}`}>{errorMsg}</div>}
        </div>
      )}
      {!onlyOrigin && (
      <div className={styles.revertIcon} onClick={onClickReverse}>
        <DoubleArrow />
      </div>
      )}

      <div className={[styles.autocompletesContainer, reversed ? styles.reversed : ""].join(" ")}>
        <AutocompleteInput<SuggestionPlaceType>
          testid="origin"
          mode={mode}
          customFirstSuggestion={isSelfCare() ? firstSuggestion : undefined}
          textValue={reversed ? (defaultDestination?.name) || '' : defaultOrigin?.name || ''}
          onSuggestionSelected={(suggestion) => {
            console.log('suggestion', suggestion)
            setOriginDest(suggestion, true);
            triggerChange(suggestion, destination, reversed);
          }}
          getSuggestions={getSuggestionsOrigin}
          getTextFromSuggestion={(suggestion) => suggestion?.name || ""}
          containerProps={{
            className: styles.inputContainer,
          }}
          inputProps={{
            placeholder: !reversed ? t('departureAdress') : t('arrivalAdress'),
            readOnly: disabled,
            disabled: disabled,
          }}
        />
        <AutocompleteInput<SuggestionPlaceType>
          testid="destination"
          modalPlaceholder="Indiquez votre destination"
          mode={mode}
          textValue={reversed ? defaultOrigin?.name || '' : defaultDestination?.name || ''}
          onSuggestionSelected={(suggestion) => {
            setOriginDest(suggestion, false);
            triggerChange(origin, suggestion, reversed);
          }}
          getSuggestions={getSuggestionsDestination}
          getTextFromSuggestion={(suggestion) => suggestion?.name || ""}
          containerProps={{
            className: `${styles.inputContainer} ${onlyOrigin ? styles.hide : ""}`
          }}
          inputProps={{
            placeholder: !reversed ? t('arrivalAdress') : t('departureAdress'),
            readOnly: disabled,
            disabled: disabled,
          }}
        />
      </div>

    </div>
  )
}