import { useEffect, useMemo, useState } from "react";

type FormDateType = {
  date: Date;
  hour: number;
  minutes: number;
  dateReturn: Date | null;
  hourReturn: number | null;
  minutesReturn: number | null;
};

function getMinDate() {
  const date = new Date();
  date.setMinutes(date.getMinutes() + 15 - (date.getMinutes() % 15));
  date.setSeconds(0);
  return date;
}

function getCurrentDatePlus23Hours() {
  return getDatePlus23Hours(getMinDate());
}

function getDateFromDateHourMinutes(date: Date, hour: number, minutes: number) {
  const newDate = new Date(date);
  newDate.setHours(hour);
  newDate.setMinutes(minutes);
  newDate.setSeconds(0);
  return newDate;
}

function getDatePlus23Hours(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(date.getHours() + 23);
  newDate.setSeconds(0);
  return newDate;
}

function isSameDate(date1?: Date | null, date2?: Date | null) {
  if (!date1 && !date2) {
    return true;
  }
  if (!date1 || !date2) {
    return false;
  }
  return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}


function getDiffDays(date1: Date, date2: Date) {

  const date1Seconds = Math.floor(date1.getTime() / 1000);
  const date2Seconds = Math.floor(date2.getTime() / 1000);

  const nbDays = ((date2Seconds - date1Seconds) / (60 * 60 * 24));
  return Math.ceil(nbDays);
}

export default function useFormDates(searchForm: {
  datetime?: string;
  returnDatetime?: string;
  defaultVrentalDays?: number;
  maxDurationDays?: number;
}): {
  formDate: FormDateType;
  setFormDate: React.Dispatch<React.SetStateAction<FormDateType>>;
  nbDays: number;
  maxDays?: number;
  onChangeNbDays: (nbDays: number) => void;

  minDate: Date;
  minHour: number;
  minMinutes: number;

  minDateReturn: Date;
  minHourReturn: number;
  minMinutesReturn: number;

  maxDateReturn: Date | null;
  maxHourReturn: number | null;
  maxMinutesReturn: number | null;
} {

  const {
    defaultVrentalDays,
    maxDurationDays: maxDurationDaysValue,
  } = searchForm || {};

  const currentDatePlus1Hour = useMemo(() => getMinDate(), []);
  const currentDatePlus23HoursReturn = useMemo(() => getCurrentDatePlus23Hours(), []);

  const defaultDatetime = searchForm?.datetime ? new Date(searchForm.datetime) : currentDatePlus1Hour;
  const defaultReturnDatetime = searchForm?.returnDatetime ? new Date(searchForm.returnDatetime) : currentDatePlus23HoursReturn;

  if (defaultVrentalDays && defaultVrentalDays > 1) {
    defaultReturnDatetime.setTime(
      defaultDatetime.getTime()
      + Math.max(0, ((defaultVrentalDays - 1) * 1000 * 60 * 60 * 24))
      + 1000 * 60 * 60 * 23
    );
  }

  const [formDate, setFormDate] = useState<FormDateType>({
    date: defaultDatetime,
    hour: defaultDatetime.getHours(),
    minutes: defaultDatetime.getMinutes(),
    dateReturn: defaultReturnDatetime,
    hourReturn: defaultReturnDatetime.getHours(),
    minutesReturn: defaultReturnDatetime.getMinutes(),
  });

  const onChangeNbDays = (nbDays: number) => {
    if (nbDays < 1) {
      return;
    }
    setFormDate((prev) => {
      const dateStart = new Date(prev.date);
      dateStart.setHours(prev.hour);
      dateStart.setMinutes(prev.minutes);
      dateStart.setSeconds(0);
      const dateReturn = new Date(dateStart.getTime() + ((nbDays - 1) * 1000 * 60 * 60 * 24) + 1000 * 60 * 60 * 23);
      return ({
        ...prev,
        dateReturn: dateReturn,
        hourReturn: dateReturn.getHours(),
        minutesReturn: dateReturn.getMinutes(),
      })
    });
  }

  const minDateReturn = useMemo(() => {
    const date = new Date(formDate.date);
    date.setHours(formDate.hour);
    date.setMinutes(formDate.minutes);
    date.setSeconds(0);
    return getDatePlus23Hours(date);
  }, [formDate.date, formDate.hour, formDate.minutes]);

  const maxDateReturn = useMemo(() => {
    if (!maxDurationDaysValue) {
      return null;
    }
    const date = new Date(formDate.date);
    date.setHours(formDate.hour);
    date.setMinutes(formDate.minutes);
    date.setSeconds(0);


    const maxDateReturn = new Date(
      date.getTime()
      + Math.max(0, ((maxDurationDaysValue - 1) * 1000 * 60 * 60 * 24))
      + 1000 * 60 * 60 * 23
    );

    return maxDateReturn;
  }, [formDate.date, formDate.hour, formDate.minutes, maxDurationDaysValue]);

  useEffect(() => {
    // If date is same day as minimum, then check hour and minutes, and set to minimum if they are lower
    if (isSameDate(formDate.date, currentDatePlus1Hour)) {
      if (formDate.hour < currentDatePlus1Hour.getHours()) {
        setFormDate((prev) => ({
          ...prev,
          hour: currentDatePlus1Hour.getHours(),
          minutes: currentDatePlus1Hour.getMinutes(),
        }));
      } else if (formDate.hour === currentDatePlus1Hour.getHours() && formDate.minutes < currentDatePlus1Hour.getMinutes()) {
        setFormDate((prev) => ({
          ...prev,
          minutes: currentDatePlus1Hour.getMinutes(),
        }));
      }
    }

    if (!formDate.dateReturn) {
      return;
    }

    const dateReturn = new Date(formDate.dateReturn);
    dateReturn.setHours(formDate.hourReturn || 0);
    dateReturn.setMinutes(formDate.minutesReturn || 0);
    dateReturn.setSeconds(0);
    if (dateReturn.getTime() < minDateReturn.getTime()) {
      setFormDate((prev) => ({
        ...prev,
        dateReturn: minDateReturn,
        hourReturn: minDateReturn.getHours(),
        minutesReturn: minDateReturn.getMinutes(),
      }));
    } else if (maxDateReturn && dateReturn.getTime() > maxDateReturn.getTime()) {

      setFormDate((prev) => ({
        ...prev,
        dateReturn: maxDateReturn,
        hourReturn: maxDateReturn.getHours(),
        minutesReturn: maxDateReturn.getMinutes(),
      }));
    }
  }, [
    formDate.date, formDate.hour, formDate.minutes,
    formDate.dateReturn, formDate.hourReturn, formDate.minutesReturn,
    minDateReturn, maxDateReturn, currentDatePlus1Hour
  ]);


  const constraints = {

    minDate: currentDatePlus1Hour,
    minHour: isSameDate(formDate.date, currentDatePlus1Hour) ? currentDatePlus1Hour.getHours() : 0,
    minMinutes: (isSameDate(formDate.date, currentDatePlus1Hour) && formDate.date?.getHours() === currentDatePlus1Hour.getHours()) ? currentDatePlus1Hour.getMinutes() : 0,

    minDateReturn,
    minHourReturn: isSameDate(formDate.dateReturn, minDateReturn) ? minDateReturn.getHours() : 0,
    minMinutesReturn: (isSameDate(formDate.dateReturn, minDateReturn) && formDate.dateReturn?.getHours() === minDateReturn.getHours()) ? minDateReturn.getMinutes() : 0,

    maxDateReturn,
    maxHourReturn: maxDateReturn ? (isSameDate(formDate.dateReturn, maxDateReturn) ? maxDateReturn.getHours() : 23) : null,
    maxMinutesReturn: maxDateReturn ? (isSameDate(formDate.dateReturn, maxDateReturn) ? maxDateReturn.getMinutes() : 59) : null,

  }

  return {
    formDate,
    setFormDate,

    nbDays: formDate.dateReturn ? getDiffDays(
      getDateFromDateHourMinutes(formDate.date, formDate.hour, formDate.minutes),
      getDateFromDateHourMinutes(formDate.dateReturn, formDate.hourReturn || 0, formDate.minutesReturn || 0),
    ) : 1,
    maxDays: maxDurationDaysValue,
    onChangeNbDays,

    ...constraints,
  };
}
