import styles from './PopupInfos.module.scss';

export default function PopupInfos({
  containerProps: { className, ...containerProps } = {},
  children,
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  children: string | number;
}) {
  return (
    <div className={[styles.container, styles.triangleDown, className].join(" ")} {...containerProps}>

      {children}
    </div>
  )
}