import { useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "usehooks-ts";


function useIsMouseInside(ref: React.RefObject<HTMLElement>): React.MutableRefObject<boolean> {
  const isMouseInsideRef = useRef(false);

  const onMouseEnter = () => {
    isMouseInsideRef.current = true;
  }
  const onMouseLeave = () => {
    isMouseInsideRef.current = false;
  }

  useEffect(() => {
    const refCurrent = ref.current;
    refCurrent?.addEventListener("mouseenter", onMouseEnter);
    refCurrent?.addEventListener("mouseleave", onMouseLeave);
    return () => {
      refCurrent?.removeEventListener("mouseenter", onMouseEnter);
      refCurrent?.removeEventListener("mouseleave", onMouseLeave);
    }
  }, [ref]);

  return isMouseInsideRef;
}


export type AutocompleteReturnType<SuggestionType> = {
  value: string;
  suggestions: SuggestionType[];
  suggestionsIsOpen: boolean;
  isLoadingSuggestions: boolean;
  selectedSuggestionIndex: number | null;
  suggestionsContainerRef: React.RefObject<HTMLDivElement>;
  isMouseInsideRef: React.MutableRefObject<boolean>;
  setSuggestionsIsOpen: (isOpen: boolean) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => boolean;
  onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  selectSuggestion: (suggestion: SuggestionType | null) => void;
  setSelectedSuggestionIndex: (index: number | null) => void;

  hasCustomFirstSuggestion: boolean;

}


export default function useAutocomplete<SuggestionType>({
  textValue,
  customFirstSuggestion,
  getSuggestions,
  getTextFromSuggestion,
  onSuggestionSelected,
}: {
  textValue?: string;
  customFirstSuggestion?: SuggestionType;
  getSuggestions: (value: string) => Promise<SuggestionType[]>;
  getTextFromSuggestion: (suggestion: SuggestionType | null) => string;
  onSuggestionSelected?: (suggestion: SuggestionType | null) => void;
}) : AutocompleteReturnType<SuggestionType> {

  const tmpDisableSearch = useRef(false);
  const suggestionsContainerRef = useRef<HTMLDivElement>(null);
  const isMouseInsideRef = useIsMouseInside(suggestionsContainerRef);
  const [value, setValue] = useState(textValue || "");
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number | null>(null);
  const [suggestionsIsOpen, setSuggestionsIsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<SuggestionType[]>(customFirstSuggestion ? [customFirstSuggestion] : []);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const debouncedValue = useDebounce<string>(value, 500);

  useEffect(() => {
    setValue(value => {
      tmpDisableSearch.current = true;
      if (value !== textValue) {
        return textValue || "";
      }
      return value;
    });
  }, [textValue]);

  const doSearch = useCallback(async () => {
    if (debouncedValue === "") {
      setSuggestions(customFirstSuggestion ? [customFirstSuggestion] : []);
      return;
    }
    setSuggestionsIsOpen(true);
    setIsLoadingSuggestions(true);
    setSelectedSuggestionIndex(null);
    const suggestions = await getSuggestions(debouncedValue);
    setSuggestions([
      ...(customFirstSuggestion ? [customFirstSuggestion] : []),
      ...suggestions
    ]);
    setIsLoadingSuggestions(false);
  }, [debouncedValue, getSuggestions, customFirstSuggestion]);

  const selectSuggestion = (suggestion: SuggestionType | null) => {
    tmpDisableSearch.current = true;
    setValue(getTextFromSuggestion(suggestion));
    setSuggestionsIsOpen(false);
    onSuggestionSelected && onSuggestionSelected(suggestion);
  }

  useEffect(() => {
    if (tmpDisableSearch.current) {
      tmpDisableSearch.current = false;
      return;
    }
    doSearch();
  }, [doSearch])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    const start = input.selectionStart; // Position actuelle du curseur
    const end = input.selectionEnd;
    const newValue = input.value;
    console.log("start", start);
    console.log("end", end);

    // Réinitialise la suggestion sélectionnée
    onSuggestionSelected && onSuggestionSelected(null);
    setSelectedSuggestionIndex(null);

    // Met à jour la valeur et restaure la position du curseur après le re-rendu
    setTimeout(() => {
      tmpDisableSearch.current = false;
      setValue(newValue);

      // Restaurer la position du curseur
        setTimeout(() => {
          input.setSelectionRange(start, end);
        }, 0);
  
    }, 0);
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      setSuggestionsIsOpen(false);
    }
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      setSuggestionsIsOpen(true);
    }
    e.preventDefault();
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

    if (e.key === "Enter") {
      if (selectedSuggestionIndex !== null) {
        selectSuggestion(suggestions[selectedSuggestionIndex]);
      }
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    if (["ArrowDown", "ArrowUp"].includes(e.key)) {
      e.preventDefault();
    }

    let newSelectedSuggestionIndex = selectedSuggestionIndex;
    if (e.key === "ArrowDown") {
      if (selectedSuggestionIndex === null) {
        newSelectedSuggestionIndex = 0;
      } else {
        newSelectedSuggestionIndex = Math.min(selectedSuggestionIndex + 1, suggestions.length - 1);
      }
    } else if (e.key === "ArrowUp") {
      if (selectedSuggestionIndex === null) {
        newSelectedSuggestionIndex = suggestions.length - 1;
      } else {
        newSelectedSuggestionIndex = Math.max(selectedSuggestionIndex - 1, 0);
      }
    }

    setSelectedSuggestionIndex(newSelectedSuggestionIndex);

    // focus on suggestion and scroll inside suggestions container
    if (newSelectedSuggestionIndex !== null && suggestionsContainerRef.current) {
      const suggestions = suggestionsContainerRef.current;
      const suggestion = suggestions?.children[newSelectedSuggestionIndex];
      suggestion?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }

    return false;
  }

  return {
    value,
    suggestions,
    suggestionsIsOpen,
    isLoadingSuggestions,
    selectedSuggestionIndex,
    suggestionsContainerRef,
    isMouseInsideRef,
    setSuggestionsIsOpen,
    onChange,
    onKeyDown,
    onKeyUp,
    selectSuggestion,
    setSelectedSuggestionIndex,

    hasCustomFirstSuggestion: !!customFirstSuggestion,

    onFocus: () => {
      setSuggestionsIsOpen(true);
    },
    onBlur: () => {
      if (isMouseInsideRef.current) {
        return;
      }
      setSuggestionsIsOpen(false);
    },
  }
}